import React from 'react';
import injectSheet from 'react-jss';
import Helmet from 'react-helmet';
import { isChinaUser } from '../config/isChina';

import Layout from '../components/layout';
import i18next from '../config/i18next';

const styles = {
    status: {
        margin: '0 30px 0 30px'
    },
    iframe: {
        border: 0,
        padding: '30px 0 0 5%',
        height: 2000,
        width: '90%'
    },
    '@media (max-width: 840px)': {
        status: {
            margin: 0
        },
        iframe: {
            height: 2800,
            width: '100%',
            padding: 0
        }
    }
};

class Status extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            isCDC: false
        };
    }

    componentDidMount() {
        this.loadTitleTranslation();

        if (isChinaUser()) {
            this.setState({ isCDC: true });
        }
    }

    /*
     * The purpose of this function is to ensure that tranlations are loaded before we set the title in the head. React Helmet does not allow React
     * nodes for the title so we cannot use Translate component. Using i18next directly would just load the key as i18next hasn't loaded the the json files yet.
     * So we need to keep track of the title state and update it once i18next loads translations
     */
    loadTitleTranslation() {
        i18next.loadNamespaces('prelogin_pages', (err) => {
            if (err) {
                console.log(err[0]);
            }

            let text = i18next.t(`prelogin_pages:gc_status`, { skipInterpolation: true });
            this.setState({ title: text });
        });
    }

    render() {
        const { classes } = this.props;
        const title = this.state.title;

        return (
            <Layout>
                <div className={classes.status}>
                    <Helmet>
                        <title>Garmin Connect | {title}</title>
                        <meta
                            name="description"
                            content="When things on Garmin Connect aren’t going quite the way they should, we’ll tell you the status of what’s working and what’s down."
                        />{' '}
                        <meta property="og:title" content="Garmin Connect | Status" />
                        <meta property="og:url" content={`https://connect.garmin.com/status`} />
                        <meta
                            property="og:description"
                            content="When things on Garmin Connect aren’t going quite the way they should, we’ll tell you the status of what’s working and what’s down."
                        />
                        <meta
                            property="og:image"
                            content="http://static.garmincdn.com/com.garmin.connect/content/images/garmin-connect-fb3.gif"
                        />
                        <meta name="twitter:title" content="Garmin Connect | Status" />
                        <meta name="twitter:url" content={`https://connect.garmin.com/status`} />
                        <meta
                            name="twitter:image"
                            content="http://static.garmincdn.com/com.garmin.connect/content/images/garmin-connect-fb3.gif"
                        />
                    </Helmet>
                    {this.state.isCDC ? (
                        <iframe
                            key={'china-status'}
                            className={classes.iframe}
                            title="Status"
                            src={'/site-status/garmin-connect-china-status-content.html'}
                            width="90%"
                            height="900"
                        />
                    ) : (
                        <iframe
                            key={'ww-status'}
                            className={classes.iframe}
                            title="Status"
                            src={'/site-status/garmin-connect-status-content.html?v=2'} // Add v=2 to cache bust an old version that had a 2 month Expires time.  This can be removed after 2 months.
                        />
                    )}
                </div>
            </Layout>
        );
    }
}

export default injectSheet(styles)(Status);
